.container {
  gap: 72px;
  padding: 0 16px 64px 16px;
}

.image {
  object-fit: contain;
  object-position: center;
  margin-left: auto;
  margin-right: auto;
}
