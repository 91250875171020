.root {
  padding-inline: 8px;
  align-items: center;
  justify-content: center;
  height: fit-content;
}
.root:hover {
  cursor: pointer;
}
.root:active {
  opacity: 0.5;
}
.fixed {
  flex: 1;
}
.icon {
  flex-shrink: 0;
}
.label {
  text-align: center;
}

.icon-start {
  margin-inline-end: 8px;
}
.icon-end {
  margin-inline-start: 8px;
}

.position-start {
  flex-direction: row;
}
.position-end {
  flex-direction: row-reverse;
}
