.container {
  position: relative;
  padding: 72px 16px 40px 16px;
  overflow: hidden;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -2;
}

.textSection {
  margin-left: 8px;
  margin-right: 8px;
}

.illustration {
  position: absolute;
  bottom: 88px;
  right: 0;
  z-index: -1;
}
