.container {
  background-color: #000;
  padding: 32px 16px;
  margin-top: auto;
}

.link {
  text-decoration: none;
}

.footerInfoContainer {
  margin-bottom: 16px;
}

.button {
  padding: 0;
}

.logo {
  object-fit: contain;
  width: fit-content;
}
