.container {
  padding: 48px 0px;
}

.itemsContainer {
  overflow-x: auto;
  width: 100%;
  margin-bottom: -12px;
}

.articleItem {
  width: 295px;
  padding-bottom: 12px;
}
