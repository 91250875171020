.container {
  background-color: #fff;
  height: 100%;
  width: 100%;
}

.header {
  padding: 16px;
  box-shadow: 0px 1px 2px rgba(3, 18, 26, 0.2);
}

.image {
  object-fit: contain;
  width: fit-content;
  max-width: 212px;
}

.content {
  padding-top: 8px;
  padding-bottom: 48px;
  padding-left: 24px;
  padding-right: 24px;
  flex: 1;
}

.menu {
  padding-top: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #cdd0d1;
}

.button {
  flex: 1;
}
