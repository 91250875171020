.container {
  background-color: #fff;
  min-height: 60px;
  padding-left: 16px;
  padding-right: 16px;
  position: sticky;
  top: 0;
  z-index: 500;
  box-shadow: 0 1px 2px 0 rgba(3, 18, 26, 0.2);
}
