.container {
  width: 90%;
  aspect-ratio: 1/1;
  border-radius: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 64px;
  background-color: #0194f3;
  position: relative;
  align-items: center;
}

.image {
  position: absolute;
  object-fit: contain;
  bottom: 0;
}

.title {
  width: 80%;
  margin-top: 15%;
  text-align: center;
  z-index: 1;
}

.content {
  width: 85%;
  margin-top: 24px;
  margin-bottom: 32px;
  text-align: center;
  z-index: 1;
}

.button {
  padding: 8px 20px;
  width: fit-content;
  z-index: 1;
}
