.buttonPagination {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.buttonPaginationDisabled {
  background-color: #f7f9fa;
}

.articleItemContent {
  flex: 1;
  padding: 16px 16px 22px 16px;
}

.articleSummary {
  height: 72px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.articleContentItemFooter {
  margin-top: auto;
  padding-top: 4px;
  box-sizing: border-box;
}

.content {
  width: 1080px;
  margin-top: 80px;
  margin-bottom: 80px;
  margin-left: auto;
  margin-right: auto;
}

.link {
  color: #0194f3;
}
