.container {
  position: absolute;
  width: 100%;
  transition: 200ms opacity ease-in-out;
}

.show {
  opacity: 1;
}

.hide {
  opacity: 0;
}

.image {
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
  object-position: center;
}
