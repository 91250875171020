.container {
  padding-inline: 180px;
  padding-block: 56px;
}
.footer {
  background-color: #03121a;
  margin-top: auto;
}
.flexOne {
  flex: 1;
}
.flexTwo {
  flex: 2;
}
.copyrightText {
  margin-left: 180px;
  margin-block: 24px;
}
.divider {
  background-color: #687176;
}
.link {
  text-decoration-line: none;
}
.directionButton {
  padding: 0px;
}
