.container {
  position: relative;
  width: 100%;
  height: 70vh;
}

.content {
  position: absolute;
  margin-left: auto;
  left: 0;
  margin-right: auto;
  right: 0;
  bottom: 10%; /* position depending on phone height */
  width: 327px;
}

.productBadge {
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 100px;
  background-color: rgba(255, 255, 255, 0.2);
}

.button {
  width: 100%;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
