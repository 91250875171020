.menu {
  flex-direction: row !important;
  align-items: center;
  padding: 16px;
  transition-property: background-color;
  transition-duration: 200ms;
  margin-inline: 8px;
}
.leftIcon {
  margin-right: 8px;
}
.rightIcon {
  margin-left: auto;
}

.active {
  color: #fff;
}
.inactive {
  color: #687176;
}

.activeBackdrop {
  background-color: #0194f3;
}
