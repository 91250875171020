.wrapper {
  position: relative;
  min-height: 384px;
}

.tabsContainer {
  background-color: transparent;
  border-bottom-width: 2px;
  border-bottom-color: #cdd0d1;
  justify-content: center;
}

.tabsContainerOverflow {
  max-width: 100vw;
  overflow-x: auto;
  scrollbar-width: none;
}
