.container {
  margin-left: 180px;
  margin-block: auto;
  width: 458px;
}
.input {
  width: 320px;
  padding-block: 0px;
}
.ctaButton {
  padding-block: 8px;
  padding-left: 12px;
  padding-right: 12px;
  width: 220px;
}
