.container {
  padding-top: 32px;
  padding-bottom: 48px;
  background-color: #fff;
}

.itemContainer {
  padding: 0 16px;
  overflow: auto;
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 16px;
}
