.button {
  min-width: 201px;
}
.container {
  padding-block: 48px;
  padding-inline: 32px;
  background-color: #0194f3;
  margin-block: 104px;
  margin-inline: 180px;
  border-radius: 20px;
  position: relative;
}
.content {
  text-align: center;
}
.contentGroup {
  z-index: 1;
}
.image {
  position: absolute;
  bottom: 0px;
  left: 0px;
  object-fit: fill;
}
