.listContainer {
  background-color: #f7f9fa;
  padding: 16px;
}

.productIcon {
  margin-left: auto;
  margin-right: auto;
}

.imageListContainer {
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
}

.listTitle {
  margin-left: auto;
  margin-right: auto;
}

.listWrapper {
  position: relative;
}

.card {
  box-sizing: border-box;
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: fit-content;
  height: 40px;
}

.imageList {
  width: fit-content;
  object-fit: contain;
}

.itemText {
  word-break: normal;
  white-space: nowrap;
}

.overlay {
  z-index: 1;
  position: absolute;
  width: 16px;
  top: 0;
  bottom: 0;
}

.leftOverlay {
  left: 0;
  background: linear-gradient(90deg, #f7f9faff 0%, #f7f9fa00 100%);
}

.rightOverlay {
  right: 0;
  background: linear-gradient(270deg, #f7f9faff 0%, #f7f9fa00 100%);
}

.link {
  text-decoration: none;
}
