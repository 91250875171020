.table{
    border-collapse: collapse;
    width: 100%;
}
.th{
    text-align: left;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;
    padding-right: 12px;
}
.td{
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;
    padding-right: 12px;
}
.tr{}