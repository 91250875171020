.root {
  background-color: #fff;
  overflow: visible;
}

.rounded {
  border-radius: 6px;
}

.flat {
  box-shadow: none;
}

.container {
  box-shadow: 0px 1px 2px rgba(3, 18, 26, 0.2);
}

.raised {
  box-shadow: 0px 2px 5px rgba(3, 18, 26, 0.15);
}

.float {
  box-shadow: 0px 4px 10px rgba(3, 18, 26, 0.15);
}

.hover {
  box-shadow: 0px 8px 16px rgba(3, 18, 26, 0.2);
}

.visible {
  overflow: visible;
}

.hidden {
  overflow: hidden;
}
