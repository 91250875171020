.articleCard {
  min-width: 295px;
  max-width: 295px;
  height: 280px;
}

.articleTitle {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.articleContent {
  padding: 0px 16px 16px 16px;
}

.readMoreButton {
  border-radius: 40px;
}

.articleSummary {
  height: 48px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.link {
  text-decoration: none;
}

.imageContainer {
  height: 125px;
}
