.background {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
.container {
  position: relative;
  box-sizing: border-box;
  min-height: 632px;
  padding-bottom: 40px;
}
.contentGroup {
  margin-top: 180px;
  margin-left: 180px;
}
.contentNumberGroup {
  z-index: 1;
}
.illustration {
  position: absolute;
  top: 200px;
  right: 0px;
  margin-left: auto;
}
.title {
  z-index: 1;
}
.thumbnail {
  z-index: 2;
}
