.container {
  padding-left: 108px;
  padding-right: 177px;
  padding-block: 32px;
  z-index: 1;
}
.image {
  margin-inline-start: 40px;
  object-fit: scale-down;
}
.marquee {
  gap: 32px;
  align-items: center;
}
.initialImages {
  flex-direction: row;
  align-self: first baseline;
  gap: 4px;
  overflow: scroll;
  scrollbar-width: none;
  position: relative;
  top: 0px;
}
.description {
  min-width: 244px;
}
