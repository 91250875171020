.langButton {
  padding-top: -4px;
  padding-bottom: -4px;
}

.title {
  margin-left: auto;
  margin-right: auto;
}

.radio {
  padding: 8px;
}

.button {
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 8px;
  margin-bottom: 8px;
}
