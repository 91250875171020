.languageButton {
  margin-left: 12px;
  min-width: 55px;
}
.dropdownLanguageMenu {
  min-width: 260px;
  padding: 16px;
  box-sizing: border-box;
}
.header {
  font-weight: 700;
  letter-spacing: 1;
  padding-bottom: 16px;
}
.menuText {
  color: #03121a;
}
.menuItem {
  padding-block: 12px;
  padding-inline: 4px;
  margin-inline: 0px;
  margin-bottom: 16px;
}
.activeMenuItem {
  background-color: none;
}
.actions {
  flex-direction: row !important;
  justify-content: flex-end;
  margin-top: 12px;
}
.cancelButton {
  margin-right: 16px;
}
