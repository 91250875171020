.root {
  background-color: #fff;
  border-color: #cdd0d1;
  transition-duration: 200ms;
  transition-property: background-color, border-color;
  transition-timing-function: ease-in;
  border-style: solid;
}
.root:disabled {
  border-color: #f2f3f3;
  background-color: #f2f3f3;
}
.root:hover {
  border-color: #687176;
}
.root:focus-within {
  border-color: rgba(1, 148, 243, 0.5);
}
.rootFocused {
  border-color: rgba(1, 148, 243, 0.5);
}
.rootError {
  border-color: #f4555a;
}

.input {
  color: #03121a;
  flex: 1;
  min-width: 0px;
  outline-style: none;
  outline-width: 0px;
  border-style: none;
  font-size: 14px;
  font-weight: 400;
  font-family: Godwit, System, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-variant-ligatures: none;
}
.input:disabled {
  color: #cdd0d1;
  cursor: default;
}
.formal {
  padding: 7px;
  border-width: 1px;
  border-radius: 6px;
}
.minimal {
  padding-inline: 4px;
  padding-top: 8px;
  padding-bottom: 8px - 1px;
  border-bottom-width: 1px;
}
.formalFocused {
  border-width: 2px;
  padding: 6px;
}
.minimalFocused {
  border-bottom-width: 2px;
  padding-bottom: 6px;
}
.formalSmall {
  padding: 5px;
}
.minimalSmall {
  padding-top: 6px;
  padding-bottom: 5px;
}
.formalSmallFocused {
  border-width: 2px;
  padding: 4px;
}
.minimalSmallFocused {
  border-bottom-width: 2px;
  padding-bottom: 4px;
}
.small {
  font-size: 14px;
  font-weight: 400;
}

.medium {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}
