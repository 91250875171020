/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
.sideBar::-webkit-scrollbar {
  background-color: unset;
  width: 8px;
}

/* background of the scrollbar except button or resizer */
.sideBar::-webkit-scrollbar-track {
  background-color: unset;
}
.sideBar::-webkit-scrollbar-track:hover {
  background-color: unset;
}

/* scrollbar itself */
.sideBar::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 4px;
  border: 2px solid rgb(28, 41, 48);
}
.sideBar::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border: 1px solid rgb(28, 41, 48);
}

/* set button(top and bottom of the scrollbar) */
.sideBar::-webkit-scrollbar-button {
  display: none;
}

.sideBar {
  overflow-y: auto;
  height: 100%;
  padding-top: 32px;
  padding-bottom: 32px;
}
