.initialCardContainer {
  overflow: scroll;
  scrollbar-width: none;
}
.container {
  padding: 16px;
  background-color: #f7f9fa;
  border-radius: 6px;
  margin-block: 16px;
  position: relative;
  overflow: hidden;
}
.button {
  min-width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: #f7f9fa;
  box-shadow: 0 4px 10px rgba(3, 18, 26, 0.15);
  align-items: center;
  justify-content: center;
}
.buttonEnabled {
  background-color: #fff;
  transition: 500ms all;
  cursor: pointer;
}
.link {
  text-decoration-line: none;
}
.partnerCard {
  padding-inline: 16px;
  padding-block: 8px;
  min-width: max-content;
}
.scrollView {
  padding-block: 4px;
  overflow: scroll;
  gap: 16px;
  padding-inline: 4px;
  scroll-behavior: smooth;
  flex: 1;
}
@supports (scrollbar-width: none) {
  .scrollbarStyle {
    scrollbar-width: none;
  }
}
/* Fallback for browsers that doesnt support scrollbar-width property */
@supports selector(::-webkit-scrollbar) {
  .scrollbarStyle::-webkit-scrollbar {
    display: none;
  }
}

.title {
  min-width: 187px;
}
.leftGradient {
  position: absolute;
  width: 16px;
  height: 70px;
  left: 290px;
  z-index: 2;
  background: linear-gradient(to right, #f7f9faff 0%, #f7f9fa00 100%);
}
.rightGradient {
  position: absolute;
  width: 16px;
  height: 70px;
  right: 85px;
  z-index: 2;
  background: linear-gradient(to left, #f7f9faff 0%, #f7f9fa00 100%);
}
