.container {
  position: relative;
  height: 300px;
}
.image {
  position: absolute;
  width: 100%;
  top: 0px;
  left: 0px;
}
.title {
  margin-left: 180px;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 1;
}
