.divider {
  margin-top: 40px;
  margin-bottom: 40px;
}

.relatedArticleContainer {
  margin-top: 40px;
  margin-bottom: 108px;
}

.item {
  width: 344px;
  height: 440px;
}

.title {
  min-height: 24px;
  max-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.summary {
  height: 96px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 4;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.readMore {
  margin-top: auto;
  margin-left: auto;
  text-decoration: none;
}

.image {
  border-radius: 6px;
}

.imageContainer {
  height: 200px;
}
