.container {
  padding-top: 48px;
  padding-bottom: 48px;
  padding-left: 16px;
  padding-right: 16px;
}

.title {
  text-align: center;
}
