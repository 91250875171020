.container {
  height: 70vh;
  width: 100%;
  position: relative;
}

.image {
  height: 70vh;
  width: 100%;
  position: absolute;
  object-fit: cover;
  object-position: center;
}
