.container {
  padding-top: 48px;
  padding-bottom: 48px;
}

.title {
  margin-left: 16px;
  margin-right: 16px;
}

.image {
  margin-inline-start: 48px;
  object-fit: scale-down;
}

.initialImages {
  flex-direction: row;
  align-self: first baseline;
  gap: 4px;
}
