.container {
  position: relative;
  height: 300px;
}
.hero {
  position: absolute;
  object-fit: cover;
  object-position: top;
  top: 0px;
  left: 0px;
}
.title {
  margin-block: auto;
  margin-left: 180px;
  width: 494px;
  z-index: 1;
}
