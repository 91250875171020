.title {
  margin-right: -8px;
  margin-left: -8px;
}

.content {
  padding: 40px 24px 64px 24px;
  background-color: #fff;
}

.button {
  padding: 4px;
  background-color: #ecf8ff;
  border-radius: 50%;
}

.buttonDisabled {
  background-color: #f2f3f3;
}
