.flexBox {
  flex-direction: row !important;
  align-items: center;
  justify-content: flex-start;
}
.header {
  justify-content: space-between;
  background-color: #ffffff;
  padding-inline: 108px;
  padding-block: 16px;
  height: 40px;
  position: sticky;
  top: 0px;
  left: 0px;
  z-index: 500;
  box-shadow: 0px 2px 10px 0px #00000012;
}
.nav {
  margin-left: 32px;
  transition: 300ms all;
}
.navActive {
  color: #007ce8;
}
.loginButton {
  margin-inline: 16px;
}
