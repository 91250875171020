.container {
  position: relative;
  width: 100%;
  height: 300px;
}

.image {
  position: absolute;
  object-fit: contain;
  object-position: top;
  width: 100%;
  z-index: -1;
}

.text {
  width: 45%;
  padding-left: 16px;
}
