.container {
  margin-block: 56px;
}
.contentGroup {
  position: relative;
  overflow: hidden;
  max-width: 800px;
  scroll-behavior: smooth;
}
.divider {
  margin-bottom: 32px;
}
